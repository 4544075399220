
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'SearchBar',

  data() {
    return {
      debouncedInput: '',
      timeout: null as ReturnType<typeof setTimeout> | null,
    };
  },

  computed: {
    ...mapGetters('search', ['keyword']),

    input: {
      get(): string {
        return this.debouncedInput;
      },
      set(val: string) {
        if (!val || val.length < 2) {
          return;
        }

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.debouncedInput = val;
          this.$emit('search', this.debouncedInput);
        }, 300);
      },
    },
  },

  created() {
    this.debouncedInput = this.keyword;
  },
});
