
import Grid from '@/components/Grid.vue';
import GridFileItem from '@/components/GridFileItem.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import SearchBar from '@/components/SearchBar.vue';
import SortSelector from '@/components/SortSelector.vue';
import OpenFileMixin from '@/mixins/open-file';
import { SearchActions } from '@/store/search/actions.enum';
// eslint-disable-next-line import/order
import Vue from 'vue';
// eslint-disable-next-line import/order
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'Search',

  mixins: [OpenFileMixin],

  components: {
    SearchBar,
    Grid,
    GridFileItem,
    LoadingSpinner,
    SortSelector,
  },

  computed: {
    ...mapGetters('search', ['items', 'loading']),
  },

  methods: {
    ...mapActions('search', {
      searchFiles: SearchActions.SEARCH,
      resetSearch: SearchActions.RESET,
    }),

    search(val: string) {
      this.searchFiles(val);
    },
  },
});
